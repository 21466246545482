<template>
  <Btn
    :title="title"
    href="mailto:info@gyerekautokolcsonzo.hu"
    iconLeft="envelope"
    :variant="variant"
    :size="size"
    :onlyIcon="onlyIcon"
  />
</template>
<script>
import Btn from "@/components/units/buttons/Btn";

export default {
  name: "BtnBookingOnline",
  components: { Btn },
  props: {
    title: {
      type: String,
      default: "Időpontfoglalás e-mailben",
    },
    variant: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: null,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss"></style>
