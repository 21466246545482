<template>
  <form class="contact-form-box" @submit.prevent="sendEmail">
    <BRow gutter-x="4" gutter-y="4">
      <BCol sm="12">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="name"
            name="name"
            placeholder="Név (kötelező)"
            required
          />
        </div>
      </BCol>
      <BCol sm="6">
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="email"
            name="email"
            placeholder="Email (kötelező)"
            required
          />
        </div>
      </BCol>
      <BCol sm="6">
        <div class="form-group">
          <input
            type="tel"
            class="form-control"
            id="phone"
            v-model="phone"
            name="phone"
            placeholder="Telefonszám (opcionális)"
          />
        </div>
      </BCol>
      <BCol sm="12">
        <div class="form-group">
          <textarea
            class="form-control"
            id="message"
            v-model="message"
            name="message"
            placeholder="Üzenet (kötelező)"
            rows="10"
            required
          ></textarea>
        </div>
      </BCol>
      <BCol sm="12">
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="privacy-policy"
              v-model="privacyPolicy"
              name="privacyPolicy"
              required
            />
            <label class="form-check-label" for="privacy-policy">
              Elfogadom az
              <a href="/adatkezelesi-szabalyzat.pdf" target="_blank">adatvédelmi szabályzatot</a>.
            </label>
          </div>
        </div>
      </BCol>
      <BCol sm="12">
        <BButton type="submit" id="send" variant="primary" class="btn-full">
          <BootstrapIcon class="left" icon="send" />
          Küldés
        </BButton>
      </BCol>
    </BRow>
  </form>
</template>
<script>
// https://www.freecodecamp.org/news/send-emails-from-your-vue-application/ || https://github.com/Seybel/vue-emailjs-demo
// https://stackoverflow.com/questions/49149550/how-to-send-an-email-with-vuejs

export default {
  name: "ContactFormBox",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      privacyPolicy: "",
    };
  },
  methods: {
    sendEmail() {
      try {
        this.sanitize();
        this.validate();
        if (this.privacyPolicy === false) return;
        // send email (call php mail endpoint)

        const mailtoUrl = `mailto:info@gyerekautokolcsonzo.hu?subject=${encodeURIComponent(
          "Érdeklődés"
        )}&body=${encodeURIComponent(this.getBody())}`;

        window.location = mailtoUrl;
      } catch (error) {
        console.error({ error });
      }

      this.reset();
    },

    reset() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.message = "";
    },

    sanitize() {},

    validate() {},

    getBody() {
      return `Név: ${this.name}
Email: ${this.email}
Telefonszám: ${this.phone}

Üzenet: ${this.message}

Elolvastam és elfogadom az adatvédelmi nyilatkozatot.
        `;
    },

    checkPrivacyPolicy() {},
  },
};
</script>

<style scoped lang="scss">
.contact-form-box {
  position: relative;
  display: block;

  input:not([type="checkbox"]),
  textarea {
    background-color: rgba($white, 0.8);

    &:focus {
      background-color: rgba($white, 1);
    }
  }

  a:hover {
    color: $primary;
    transition: $transition-base;
  }
}

.form-check-label {
  user-select: none;

  a {
    color: $primary;
    transition: $transition-base;

    &:hover {
      color: $primary-hover-bg;
    }
  }
}
</style>
