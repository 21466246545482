<template>
  <div class="section-content">
    <slot />
  </div>
</template>
<script>
export default {
  name: "SectionContent",
};
</script>

<style scoped lang="scss"></style>
