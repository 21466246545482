<template>
  <div class="section-title">
    <h1 v-if="headingLevel === '1'">{{ title }}</h1>
    <h2 v-else-if="headingLevel === '2'">{{ title }}</h2>
    <h3 v-else-if="headingLevel === '3'">{{ title }}</h3>
    <h4 v-else-if="headingLevel === '4'">{{ title }}</h4>
    <h5 v-else-if="headingLevel === '5'">{{ title }}</h5>
    <h6 v-else-if="headingLevel === '6'">{{ title }}</h6>
    <div v-else>{{ title }}</div>
  </div>
</template>
<script>
export default {
  name: "SectionTitle",
  props: {
    title: String,
    headingLevel: {
      type: String,
      default: "2",
    },
  },
};
</script>

<style scoped lang="scss">
.section-title {
  position: relative;
  margin: 0 0 $section-padding 0;
  text-align: center;

  @include set-headings($font-size-level-6, $font-weight-bolder);

  @include media-breakpoint-up(sm) {
    @include set-headings($font-size-level-7, $font-weight-bolder);
  }
}
</style>
