<template>
  <span v-if="!isBootstrap" class="icon">
    <img v-if="iconPath" :src="iconPath" :alt="icon" :class="cssClasses" />
  </span>
  <BootstrapIcon
    v-else
    :icon="icon"
    :variant="variant"
    :size="size"
    :flipH="flipH"
    :flipV="flipV"
    :rotate="rotate"
    :animation="animation"
  />
</template>
<script>
export default {
  name: "Icon",

  props: {
    isBootstrap: Boolean,
    icon: String,

    variant: String,
    size: String,
    flipH: Boolean,
    flipV: Boolean,
    rotate: [String, Number],
    animation: String,
  },

  computed: {
    iconPath() {
      let iconPath = "";

      let extSvg = this.checkIconExists("svg");
      let extPng = this.checkIconExists("png");

      if (extSvg) {
        iconPath = extSvg;
      } else if (extPng) {
        iconPath = extPng;
      }

      return iconPath;
    },

    cssClasses() {
      const classes = [];

      if (this.size) classes.push(`icon--size-${this.size}`);

      return classes;
    },
  },

  methods: {
    checkIconExists(ext = "svg") {
      try {
        return require("@/assets/icons/icon-" + this.icon + "." + ext);
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.icon {
  img {
    object-fit: contain;
  }

  &--size {
    &-sm {
      font-size: 0.75em;
      width: 0.75em;
      height: 0.75em;
    }

    &-md {
      font-size: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
    }

    &-lg {
      font-size: 1.33333333rem;
      width: 1.33333333rem;
      height: 1.33333333rem;
    }

    &-2x {
      font-size: 2rem;
      width: 2rem;
      height: 2rem;
    }

    &-3x {
      font-size: 3rem;
      width: 3rem;
      height: 3rem;
    }

    &-4x {
      font-size: 4rem;
      width: 4rem;
      height: 4rem;
    }

    &-5x {
      font-size: 5rem;
      width: 5rem;
      height: 5rem;
    }
  }
}
</style>
