<template>
  <div id="opening-hours" class="navbar fixed-bottom navbar-light">
    <div class="container">
      <a href="tel:+36307488928" title="Nyitvatartás">
        <BootstrapIcon icon="clock" size="2x" class="me-4" />
        <span>
          Helyszíni autózás üzletünk parkos környezetében <strong>bejelentkezés alapján</strong>, akár fél órán belül
          minden nap <strong>9-18 óra</strong> között,
          <strong><a href="/szolgaltatasaink/berles-nehany-percre-orara">2000 Ft / 30 perc</a></strong
          >.
        </span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "OpeningHours",
};
</script>

<style lang="scss">
#opening-hours {
  box-shadow: $box-shadow;
  background-color: $white;
  z-index: $zindex-sticky;
}
</style>
