<template>
  <Btn :title="title" href="tel:+36307488928" iconLeft="phone" :variant="variant" :size="size" :onlyIcon="onlyIcon" />
</template>
<script>
import Btn from "@/components/units/buttons/Btn";

export default {
  name: "BtnBookingPhone",
  components: { Btn },
  props: {
    title: {
      type: String,
      default: "Időpontfoglalás telefonon",
    },
    variant: {
      type: String,
      default: "outline-primary",
    },
    size: {
      type: String,
      default: null,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss"></style>
