<template>
  <SectionBlock
    class="bg-dark text-white section-with-background bg-opacity-75"
    :style="{
      'background-image': `url(${require('@/assets/section-contact-bg.jpg')})`,
    }"
  >
    <SectionContent>
      <BRow gutterX="4" gutterY="4">
        <BCol sm="7">
          <p>
            Még bizonytalan vagy a kölcsönzéssel kapcsolatban, vagy nem tudod melyik elektromos kisautó lenne a
            legideálisabb gyermekednek?
          </p>
          <ContactInfo />
          <p>Üzletünkben akár bankkártyával is fizethetsz!</p>
          <div class="payment-methods">
            <Icon icon="mastercard" size="3x" />
            <Icon icon="maestro" size="3x" />
            <Icon icon="visa" size="3x" />
            <Icon icon="google-pay" size="3x" />
            <Icon icon="apple-pay" size="3x" />
          </div>
          <p>Kövess minket itt is:</p>
          <SocialInfo />
        </BCol>
        <BCol sm="5">
          <ContactFormBox />
        </BCol>
      </BRow>
    </SectionContent>
  </SectionBlock>
</template>
<script>
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import ContactInfo from "@/components/layout/sections/parts/contact/ContactInfo";
import SocialInfo from "@/components/layout/sections/parts/contact/SocialInfo";
import ContactFormBox from "@/components/layout/forms/ContactFormBox";
import Icon from "@/components/units/Icon";

export default {
  name: "SectionContact",
  components: { Icon, SectionBlock, ContactFormBox, SocialInfo, ContactInfo, SectionContent },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.section-content {
  text-align: center;

  @include media-breakpoint-up(sm) {
    text-align: left;
  }

  .payment-methods {
    display: flex;
    margin: $spacer 0;
    gap: calc(#{$spacer} / 4);
    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: flex-start;
    }
  }
}
</style>
