<template>
  <section>
    <BContainer>
      <slot />
    </BContainer>
  </section>
</template>

<script>
export default {
  name: "SectionBlock",
};
</script>

<style scoped lang="scss">
section {
  position: relative;
  padding: $section-padding 0;

  &.section-with-background {
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba($gray-900, 0.7);
  }
}
</style>
