<template>
  <div class="contact-info" :class="{ vertical: vertical }">
    <ul>
      <li>
        <a href="mailto:info@gyerekautokolcsonzo.hu" title="E-mail cím">
          <BootstrapIcon icon="envelope" size="2x" />
          <span>info@gyerekautokolcsonzo.hu</span>
        </a>
      </li>
      <li>
        <a href="tel:+36307488928" title="Telefonszám">
          <BootstrapIcon icon="phone" size="2x" />
          <span>+36 (30) 748 - 8928</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.google.com/maps/place/Budapest,+Vir%C3%A1g+u.+17,+1045/data=!4m2!3m1!1s0x4741da4bfb31d2d9:0xbc1581372a08016f?sa=X&ved=2ahUKEwidhKXcqN33AhXNhv0HHXEfAM4Q8gF6BAgbEAE"
          target="_blank"
          title="Cím"
        >
          <BootstrapIcon icon="geo-alt" size="2x" />
          <span>1043 Budapest, Virág utca 17. (park felől, ingyenes parkolás üzletünk körül)</span>
        </a>
      </li>
      <li v-if="!vertical">
        <a href="tel:+36307488928" title="Nyitvatartás">
          <BootstrapIcon icon="clock" size="2x" />
          <span
            >Telefonos bejelentkezés alapján, a hét minden napján, évszaktól függetlenül 9:00 - 18:00 óra között</span
          >
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "ContactInfo",
  props: {
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.contact-info {
  margin: $spacer 0;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: $spacer;

    li {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      @include set-font-style($font-size-level-4, $font-weight-normal, $white);

      @include media-breakpoint-up(sm) {
        justify-content: flex-start;
      }

      .bi {
        display: block;
        @include set-font-style($font-size-level-7, $font-weight-normal, $white);
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
          display: inline;
          margin: 0 $spacer 0 0;
        }
      }

      a:hover {
        color: $primary;
        transition: $transition-base;

        .bi {
          color: $primary;
          transition: $transition-base;
        }
      }
    }
  }

  &.vertical {
    ul {
      flex-direction: row;
      gap: $spacer;

      li {
        flex-direction: column;
        flex-wrap: wrap;
        flex: 1;
        justify-content: space-evenly;
        text-align: center;

        @include set-font-style($font-size-level-5, $font-weight-normal, $gray-900);

        .bi {
          display: block;
          width: 100%;
          margin: 0 0 $spacer 0;
          @include set-font-style($font-size-level-10, $font-weight-normal, $gray-900);
        }
      }
    }
  }
}
</style>
