<template>
  <div class="social-info">
    <ul>
      <li>
        <a href="https://www.facebook.com/kicsikocsikolcsonzo" target="_blank">
          <BootstrapIcon icon="facebook" size="2x" />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/gyerekautokolcsonzo" target="_blank">
          <BootstrapIcon icon="instagram" size="2x" />
        </a>
      </li>
      <li>
        <a href="https://www.tiktok.com/@gyerekautokolcsonzo" target="_blank">
          <BootstrapIcon icon="tiktok" size="2x" />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SocialInfo",
};
</script>

<style scoped lang="scss">
.social-info {
  margin: $spacer 0;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: $spacer;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: flex-start;
    }

    li {
      a:hover {
        .bi {
          color: $primary;
          transition: $transition-base;
        }
      }
    }
  }
}
</style>
