<template>
  <BButton :href="href" :to="to" :variant="variant" :size="size">
    <BootstrapIcon v-if="iconLeft" class="icon-left" :icon="iconLeft" :animation="animation" />
    <span class="btn-text" v-if="!onlyIcon">{{ title }}</span>
    <BootstrapIcon v-if="iconRight" class="icon-right" :icon="iconRight" :animation="animation" />
  </BButton>
</template>
<script>
export default {
  name: "Btn",
  props: {
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    animation: String,
  },
};
</script>

<style lang="scss">
.btn {
  color: $white;
  border-radius: $border-radius-xl !important;
  flex-wrap: nowrap;
  white-space: nowrap;
  box-shadow: none !important;

  &-primary {
    color: $white !important;
    background-color: $primary !important;

    &:hover {
      background-color: $primary-hover-bg !important;
      border-color: $primary-hover-border !important;
    }
  }

  &-outline-primary {
    color: $primary !important;

    &:hover {
      color: $white !important;
      background-color: $primary-hover-bg !important;
      border-color: $primary-hover-border !important;
    }
  }

  &-bg-light {
    color: $primary !important;
    background-color: $white !important;

    &:hover {
      background-color: $primary-hover-bg !important;
      border-color: $primary-hover-border !important;
    }
  }

  &-full {
    width: 100%;
  }

  .bi {
    &.icon-left {
      @include media-breakpoint-up(xl) {
        margin-right: calc(#{$spacer} / 2);
      }
    }

    &.icon-right {
      @include media-breakpoint-up(xl) {
        margin-left: calc(#{$spacer} / 2);
      }
    }
  }

  .btn-text {
    display: none;

    @include media-breakpoint-up(xl) {
      display: inline;
    }
  }
}
</style>
