<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light primary-menu">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img :src="require('@/assets/logos/logo.png')" alt="Kicsikocsim elektromos gyerekautó kölcsönző" />
      </router-link>
      <div class="primary-menu-action-buttons-mobile">
        <BtnBookingPhone title="+36 (30) 748 - 8928" />
      </div>
      <BButton
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </BButton>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav primary-menu-list">
          <li class="nav-item" :class="{ active: isActive(menuItem) }" v-for="menuItem in menuItems" :key="menuItem.id">
            <router-link class="nav-link" :to="menuItem.to">
              {{ menuItem.title }}
            </router-link>
          </li>
        </ul>
        <div class="primary-menu-action-buttons ms-auto">
          <BtnBookingOnline />
          <BtnBookingPhone />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import BtnBookingOnline from "@/components/units/buttons/BtnBookingOnline";
import BtnBookingPhone from "@/components/units/buttons/BtnBookingPhone";

export default {
  name: "PrimaryMenu",
  components: { BtnBookingOnline, BtnBookingPhone },
  data() {
    return {
      menuItems: [
        {
          id: 1,
          title: "Főoldal",
          to: "/",
        },
        {
          id: 2,
          title: "Magunkról",
          to: "/magunkrol/",
        },
        {
          id: 3,
          title: "Áraink",
          to: "/szolgaltatasaink/",
        },
        {
          id: 4,
          title: "Járműveink",
          to: "/jarmuveink/",
        },
        {
          id: 5,
          title: "Galéria",
          to: "/galeria/",
        },
        {
          id: 6,
          title: "GY.I.K.",
          to: "/gyakran-ismetelt-kerdesek/",
        },
        {
          id: 7,
          title: "Blog",
          to: "/blog/",
        },
        {
          id: 8,
          title: "Kapcsolat",
          to: "/kapcsolat/",
        },
      ],
    };
  },
  methods: {
    isActive(menuItem) {
      if (this.$route.path === "/" && menuItem.to === "/") return true;
      if (menuItem.to === "/") return false;

      return this.$route.path.includes(menuItem.to);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

$logo-height: 80px;

.navbar {
  padding: 0;
  box-shadow: $box-shadow;
  background-color: $white;

  &-toggler {
    &:hover,
    &:focus {
      background-color: inherit;
    }
  }

  &-brand {
    margin-right: 0;

    @include media-breakpoint-up(sm) {
      margin-right: $spacer;
    }

    img {
      height: calc(#{$logo-height} / 1.5);

      @include media-breakpoint-up(sm) {
        height: $logo-height;
      }
    }
  }

  &-nav {
    position: relative;
    text-align: center;

    @include media-breakpoint-up(sm) {
      text-align: left;
    }

    .nav-item {
      .nav-link {
        @include set-font-style($font-size-base, $font-weight-normal, $gray-900, uppercase, $font-family-heading);
        letter-spacing: 1px;
      }

      &:hover,
      &.active {
        .nav-link {
          color: $primary;
        }
      }
    }
  }

  .primary-menu-action-buttons-mobile {
    display: inline-flex;
    gap: $spacer;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .primary-menu-action-buttons {
    display: none;
    gap: $spacer;

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }
}
</style>
