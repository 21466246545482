<template>
  <footer>
    <div class="container">
      <BRow gutterX="4" gutterY="4">
        <BCol sm="6">
          <div class="footer-logo">
            <RouterLink to="/"><strong>KicsiKocsik</strong> - Elektromos gyerekautó kölcsönző</RouterLink>
          </div>
        </BCol>
        <BCol sm="6">
          <SocialInfo />
        </BCol>
      </BRow>
    </div>
  </footer>
  <OpeningHours />
</template>
<script>
import SocialInfo from "@/components/layout/sections/parts/contact/SocialInfo";
import OpeningHours from "@/components/layout/OpeningHours";

export default {
  name: "Footer",
  components: { SocialInfo, OpeningHours },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

footer {
  position: relative;
  z-index: $zindex-fixed;
  background-color: $black;
  color: $white;
  padding: $spacer 0;

  .footer-logo {
    text-align: center;

    @include media-breakpoint-up(sm) {
      text-align: left;
    }
  }

  .social-info {
    margin: 0 !important;

    ul {
      justify-content: center !important;

      @include media-breakpoint-up(sm) {
        justify-content: end !important;
      }

      li {
        .bi {
          font-size: $font-size-level-4 !important;
        }
      }
    }
  }
}
</style>
